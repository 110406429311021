import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ClientRiskProfile, ProspectStateInfo } from 'types/clients/clients.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientSectionProspectSteps from './ClientSectionProspectSteps';

const ClientSectionProspectStepsContainer = (props: Props) => {
    const { steps, validationStep, riskProfile } = props;
    const { t } = useTranslation('clients');

    // Orden:
    // Identidad - VALIDATE_PERSON - 3
    // Datos personales - PERSONAL_DATA - 2
    // Datos contacto - CONTACT_INFO - 1
    // Datos complementarios - COMPLEMENTARY_DATA - 9
    // Foto DNI - UPLOAD_DOCUMENT - 5
    // Test inversor - INVESTMENT_TEST - 4
    // Prueba de ingresos - PROOF_OF_INCOME - 6
    // Contrato. - CONTRACT - 7
    const [firstSteps, lastSteps] = React.useMemo(() => {
        let filteredSteps = steps;
        let orderSteps = [3, 2, 1, 9, 5, 4, 6, 7];

        if (riskProfile === 'LOW') {
            filteredSteps = steps.filter(s => s.name != t('PROOF_OF_INCOME'));
            orderSteps = [3, 2, 1, 9, 5, 4, 7];
        }

        const orderMap = new Map(orderSteps.map((id, index) => [id, index]));
        const sortedSteps = filteredSteps
            .slice()
            .sort((a, b) => orderMap.get(a.id)! - orderMap.get(b.id)!);
        return [
            [{ ...sortedSteps[0], status: validationStep }, ...sortedSteps.slice(1, 4)],
            sortedSteps.slice(4),
        ];
    }, [steps, riskProfile, validationStep, t]);

    const childProps = {
        t,
        firstSteps,
        lastSteps,
    };

    return <ClientSectionProspectSteps {...childProps} />;
};

const propTypes = {};

interface extraProps {
    steps: ProspectStateInfo[];
    riskProfile?: ClientRiskProfile | null;
    validationStep: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionProspectStepsContainer.propTypes = propTypes;

export default ClientSectionProspectStepsContainer;
