import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsAffidavitDetail from './ChannelsAffidavitDetail';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { useTranslation } from 'react-i18next';
import { useApproveAffidavit, useRejectAffidavit } from 'hooks/api/channelsAffidavit.hooks';
import { REJECTED, tRequiredFieldError } from 'constants/appConstants';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_DECLARATIONS } from 'constants/abilities.constants';

const ChannelsAffidavitDetailContainer = (props: Props) => {
    const { close, setSnackBarMessage, row } = props;
    const { t } = useTranslation('channelsAffidavit');
    const { isHiddenByAbility } = useGetActionsReestrictions();
    const [rejectReason, setRejectReason] = React.useState<string>(row.declaration?.reason ?? '');
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [fileUrl, setFileUrl] = React.useState<string | null>(row.declaration?.filePath ?? null);

    const { isLoading: isLoadingReject, rejectDeclaration } = useRejectAffidavit(
        close,
        setSnackBarMessage,
    );
    const { isLoading: isLoadingApprove, approveDeclaration } = useApproveAffidavit(
        close,
        setSnackBarMessage,
    );

    const handleSubmit = (action: 'approve' | 'reject' | null) => {
        if (!fileUrl && !rejectReason) {
            setErrorMessage(tRequiredFieldError);
            return;
        } else {
            setErrorMessage(null);
        }
        switch (action) {
            case 'approve':
                return approveDeclaration({
                    id: row.declaration?.id as number,
                    body: { filePath: fileUrl as string },
                });
            case 'reject':
                return rejectDeclaration({
                    id: row.declaration?.id as number,
                    body: { reason: rejectReason },
                });

            default:
                return close();
        }
    };

    const childProps = {
        ...props,
        t,
        rejectReason,
        setRejectReason,
        errorMessage,
        setErrorMessage,
        fileUrl,
        setFileUrl,
        isLoading: isLoadingApprove || isLoadingReject,
        handleSubmit,
        canApproveOrReject:
            !row.declaration?.declarationPath ||
            !(row.declaration.status === REJECTED && row.status === 'CANCELLED') ||
            !isHiddenByAbility(UPDATE_DECLARATIONS),
    };

    return <ChannelsAffidavitDetail {...childProps} />;
};

const propTypes = {
    setSnackBarMessage: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

interface extraProps {
    row: ChannnelsAffidavit;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitDetailContainer.propTypes = propTypes;

export default ChannelsAffidavitDetailContainer;
