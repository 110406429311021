import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, Chip, Typography } from '@mui/material';
import { tCommon, tModal } from 'constants/appConstants';
import { getColorByStatus } from 'utils/helpers/commonHelper';
import { ModalHeaderProps } from 'types/common/channels.types';

const ChannelsModalHeader = ({ status, operationType, modalName, t }: Props) => (
    <Box display="flex" alignItems="center">
        <Typography component="span" fontSize={20} mr={1.25}>
            {t(`${modalName}_detail`, tModal)}
        </Typography>
        <Chip sx={{ m: 0.625, ...getColorByStatus(status) }} label={t(status, tCommon)} />
        <Chip color="secondary" variant="outlined" sx={{ m: 0.625 }} label={t(operationType)} />
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps extends ModalHeaderProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalHeader.propTypes = propTypes;

export default ChannelsModalHeader;
