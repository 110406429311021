import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Tooltip, IconButton } from '@mui/material';
import { ActionIconButtonProps } from 'types/common/channels.types';

const ActionIconButton = ({ tooltipText, icon, disabled, handleClick }: Props) => (
    <Tooltip placement="bottom" title={tooltipText}>
        <IconButton onClick={handleClick} disabled={disabled} color="primary">
            {icon}
        </IconButton>
    </Tooltip>
);

const propTypes = {};

const defaultProps = {};

interface extraProps extends ActionIconButtonProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ActionIconButton.propTypes = propTypes;
ActionIconButton.defaultProps = defaultProps;

export default ActionIconButton;
