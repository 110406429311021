import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ClientSectionProspectSteps.styled';
import { ProspectStateInfo } from 'types/clients/clients.types';
import { Stack } from '@mui/material';
import StatusTable from 'components/common/StatusTable';

const ClientSectionProspectSteps = (props: Props) => {
    const { firstSteps, lastSteps, t } = props;
    return (
        <Stack spacing={2} direction={'column'}>
            {firstSteps && <StatusTable content={firstSteps} />}
            {lastSteps && <StatusTable content={lastSteps} />}
        </Stack>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    firstSteps?: ProspectStateInfo[];
    lastSteps?: ProspectStateInfo[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientSectionProspectSteps.propTypes = propTypes;

export default ClientSectionProspectSteps;
