import St from '../../ClientExtractionsPage/ClientExtractionsDetail/ClientExtractionsDetail.styled';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    AlertColor,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
} from '@mui/material';
import { ClientBankAccountApi } from 'types/clientBankAccounts/clientBankAccounts.api.types';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { getColorByStatus, getFullName, getMarketAccounts } from 'utils/helpers/commonHelper';
import { formatDate, formatDateWithHour } from 'utils/helpers/dateHelper';
import { UPDATE_APP_USERS_BANK_ACCOUNTS } from 'constants/abilities.constants';
import {
    BROKER,
    DESTINATION,
    INTERMEDIARY,
    LOCAL_ACCOUNTS,
    PENDING_ACCEPT,
    PENDING_DELETE,
    tChannelsCommon,
    tCommon,
} from 'constants/appConstants';
import ClientBankAccountsDetailActions from '../ClientBankAccountsDetailActions';
import { SortedBankAccount } from 'types/clientExtractions/clientExtractions.types';
import BankAccountDetail from 'components/common/BankAccountDetail';
import CustomIconSelector from 'components/common/CustomIconSelector';

const statusToShowActions = [PENDING_ACCEPT, PENDING_DELETE];

const ClientBankAccountsDetails = ({
    row: {
        id,
        accountNumber,
        currency,
        description,
        status,
        type,
        reason,
        createdAt,
        decisionAt,
        user,
        operator,
    },
    accountsInfo,
    close,
    setSnackBarMessage,
    t,
    isHiddenByAbility,
}: Props) => (
    <Dialog open={true} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>
            {t('client_bank_accounts_detail')}
            <Chip
                sx={{
                    m: 0.625,
                    minWidth: '25%',
                    marginLeft: '5%',
                    ...getColorByStatus(status),
                }}
                label={t(status, tCommon)}
            />
            <Chip
                color="secondary"
                variant="outlined"
                sx={{ m: 0.625, minWidth: '25%' }}
                label={t(type)}
            />
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={close}
            sx={{
                color: theme => theme.palette.grey[500],
                position: 'absolute',
                right: 8,
                top: 8,
            }}>
            <CustomIconSelector type="close" />
        </IconButton>
        <DialogContent>
            <Grid container spacing={2} px={3} py={0} alignItems="baseline">
                <Grid container mt={1.25}>
                    <ChannelsModalItemGrid
                        column={8}
                        label={t('client_bank_accounts_list_client')}
                        text={getFullName(user)}
                    />
                    <ChannelsModalItemGrid
                        column={4}
                        label={t('dni', tChannelsCommon)}
                        text={user.docNumber}
                    />
                    {user.marketAccounts.length > 0 && (
                        <ChannelsModalItemGrid
                            column={8}
                            label={t('customer_number', tChannelsCommon)}
                            text={getMarketAccounts(user.marketAccounts)}
                        />
                    )}
                    <ChannelsModalItemGrid
                        column={4}
                        label={t('cuit', tChannelsCommon)}
                        text={user.taxDocNumber}
                    />
                </Grid>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <Grid container item xs={6}>
                    <St.DetailTitle>{t('client_bank_accounts_title')}</St.DetailTitle>
                    {LOCAL_ACCOUNTS.includes(type) && (
                        <>
                            <ChannelsModalItemGrid
                                label={t('client_bank_accounts_list_account_cbu')}
                                text={accountNumber}
                            />
                            <ChannelsModalItemGrid
                                label={t('client_bank_accounts_list_account_currency')}
                                text={currency}
                            />
                        </>
                    )}
                    {accountsInfo?.BROKER && (
                        <BankAccountDetail
                            editable={status === PENDING_ACCEPT}
                            bankAccountId={id}
                            type={BROKER}
                            accountDetail={accountsInfo.BROKER}
                        />
                    )}

                    {accountsInfo?.DESTINATION && (
                        <BankAccountDetail
                            editable={status === PENDING_ACCEPT}
                            bankAccountId={id}
                            type={DESTINATION}
                            accountDetail={accountsInfo.DESTINATION}
                        />
                    )}
                    <ChannelsModalItemGrid
                        label={t('client_bank_accounts_createdAt')}
                        text={formatDate(createdAt)}
                    />
                    <ChannelsModalItemGrid
                        label={t('client_bank_accounts_description')}
                        text={description}
                    />
                </Grid>
                {accountsInfo?.INTERMEDIARY && (
                    <Grid container item xs={6}>
                        <St.DetailTitle>
                            {t('client_bank_accounts_intermediary_title')}
                        </St.DetailTitle>
                        {accountsInfo.INTERMEDIARY.map(accountDetail => (
                            <BankAccountDetail
                                editable={status === PENDING_ACCEPT}
                                bankAccountId={id}
                                key={accountDetail.id}
                                type={INTERMEDIARY}
                                accountDetail={accountDetail}
                            />
                        ))}
                    </Grid>
                )}
                {reason && (
                    <>
                        <Divider sx={{ width: '100%', mt: 1.25 }} />
                        <ChannelsModalItemGrid
                            column={12}
                            label={t('reason', tChannelsCommon)}
                            text={reason}
                        />
                    </>
                )}
                {operator && decisionAt && (
                    <>
                        <Divider sx={{ width: '100%', mt: 1.25 }} />
                        <ChannelsModalItemGrid
                            column={12}
                            label={t('modified', tChannelsCommon)}
                            text={`${getFullName(operator)} - ${formatDateWithHour(decisionAt)}`}
                        />
                    </>
                )}
                {statusToShowActions.includes(status) &&
                    !isHiddenByAbility(UPDATE_APP_USERS_BANK_ACCOUNTS) && (
                        <ClientBankAccountsDetailActions
                            id={id}
                            status={status}
                            type={type}
                            currency={currency}
                            close={close}
                            setSnackBarMessage={setSnackBarMessage}
                        />
                    )}
            </Grid>
        </DialogContent>
    </Dialog>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: ClientBankAccountApi;
    accountsInfo?: SortedBankAccount;
    close(): void;
    isHiddenByAbility: (ability: string) => boolean;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsDetails.propTypes = propTypes;

export default ClientBankAccountsDetails;
