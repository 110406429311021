import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { BankAccountExtraData } from 'types/clientExtractions/clientExtractions.types';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import { BROKER, ICON_CELL_SIZE, tModal } from 'constants/appConstants';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import CustomIconSelector from '../CustomIconSelector';
import ClientBankAccountsEditForm from 'components/pages/ClientBankAccountsPage/ClientBankAccountsEditForm';
import ActionIconButton from '../ActionIconButton';

const BankAccountDetail = ({
    accountDetail,
    type,
    showEditModal,
    bankAccountId,
    editable,
    isLoading,
    SnackBar,
    downloadFile,
    setShowEditModal,
    t,
}: Props) => {
    const {
        appellation,
        aba,
        swift,
        iban,
        broker,
        brokerCustomerCode,
        exteriorBankAccountStatements,
    } = accountDetail;
    return (
        <>
            {editable && (
                <Box display="flex" alignItems="center" justifyContent="end" width="100%">
                    <Tooltip title={t('edit')}>
                        <IconButton color="secondary" onClick={() => setShowEditModal(true)}>
                            <CustomIconSelector type="edit" sx={ICON_CELL_SIZE} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            {type === BROKER && (
                <>
                    <ChannelsModalItemGrid label={t('broker_name')} text={broker} />
                    <ChannelsModalItemGrid
                        label={t('broker_customer_code')}
                        text={brokerCustomerCode}
                    />
                </>
            )}
            <ChannelsModalItemGrid label={t('appellation')} text={appellation} />
            <ChannelsModalItemGrid label={t('aba')} text={aba} />
            <ChannelsModalItemGrid label={t('swift')} text={swift} />
            <ChannelsModalItemGrid label={t('iban')} text={iban} />

            {exteriorBankAccountStatements && exteriorBankAccountStatements.length > 0 && (
                <>
                    <Typography fontSize={16} fontWeight={600} my={1.5} mx={1.5}>
                        {t('statements_title')}
                    </Typography>
                    {exteriorBankAccountStatements.map(({ statementPath }, index: number) => (
                        <Grid
                            mx={2}
                            mb={1.5}
                            item
                            xs={12}
                            key={index}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between">
                            <Typography>{t(`statement_${index + 1}_detail`)}</Typography>

                            <ActionIconButton
                                tooltipText={t('download', tModal)}
                                disabled={isLoading}
                                icon={<CustomIconSelector type="download" />}
                                handleClick={() => downloadFile(statementPath)}
                            />
                        </Grid>
                    ))}
                </>
            )}

            {showEditModal && bankAccountId && (
                <ClientBankAccountsEditForm
                    id={bankAccountId}
                    open={showEditModal}
                    bankAccountExterior={accountDetail}
                    type={type}
                    close={() => setShowEditModal(false)}
                />
            )}
            <SnackBar />
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    type: string;
    accountDetail: BankAccountExtraData;
    showEditModal: boolean;
    bankAccountId?: string;
    editable?: boolean;
    isLoading: boolean;
    setShowEditModal(showEditModal: boolean): void;
    downloadFile(fileUrl: string): void;
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountDetail.propTypes = propTypes;
BankAccountDetail.defaultProps = defaultProps;

export default BankAccountDetail;
