import * as React from 'react';
import PropTypes from 'prop-types';
import St from './ChannelsCollateralDetail.styled';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Grid } from '@mui/material';
import {
    CollateralDefinition,
    collaterals,
} from 'types/channelsCollateral/channelsCollateral.types';
import { TAKER, tChannelsCommon } from 'constants/appConstants';
import { statesToShowActionsContainer } from 'utils/helpers/channelsCommonHelper';
import ChannelsCollateralDetailActions from '../ChannelsCollateralDetailActions';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import ChannelsCollateralDetailWarranty from '../ChannelsCollateralDetailWarranty';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';

const ChannelsCollateralDetail = ({
    row: {
        id,
        customerCode,
        currency,
        amount,
        rate,
        term,
        createdAt,
        channel,
        user,
        operator,
        buySell,
        status,
        lastModified,
        updatedAt,
        reason,
    },
    localInstrumentsUnderWarranty,
    totalAmount,
    action,
    setAction,
    handleChange,
    handleAcquireOrReleaseOrder,
    close,
    t,
}: Props) => (
    <Grid container spacing={2} px={3} py={2}>
        <Grid item container xs={12} md={6}>
            <St.DetailTitle>{t('information', tChannelsCommon)}</St.DetailTitle>
            <ChannelsModalItemGrid label={t('customer_number')} text={customerCode} />
            <ChannelsModalItemGrid label={t('currency')} text={currency} />
            <ChannelsModalItemGrid
                label={t('amount')}
                text={convertCurrencyFormat(amount, currency)}
            />
            <ChannelsModalItemGrid
                label={t('rate')}
                text={String(rate) === '0' ? 'MKT' : `${rate}%`}
            />
            <ChannelsModalItemGrid
                label={t('term')}
                text={`${term} ${term > 1 ? ' Días' : ' Día'}`}
            />
        </Grid>
        <Grid item container xs={12} md={6} alignSelf="baseline">
            <St.DetailTitle>{t('additional_information', tChannelsCommon)}</St.DetailTitle>
            <ChannelsModalItemGrid label={t('date')} text={formatDateWithHour(createdAt)} />
            <ChannelsModalItemGrid label={t('channel')} text={getFullName(channel)} />
            <ChannelsModalItemGrid label={t('customer')} text={getFullName(user)} />
            <ChannelsModalItemGrid label={t('operator')} text={getFullName(operator)} />
        </Grid>
        {lastModified && (
            <ChannelsModalItemGrid
                column={12}
                label={t('modified')}
                text={`${getFullName(lastModified)} - ${formatDateWithHour(updatedAt)}`}
            />
        )}
        {reason && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <ChannelsModalItemGrid column={12} label={t('reason')} text={reason} />
            </>
        )}
        {buySell === TAKER && localInstrumentsUnderWarranty.length > 0 && (
            <ChannelsCollateralDetailWarranty
                currency={currency}
                localInstrumentsUnderWarranty={localInstrumentsUnderWarranty}
                inputsDisabled={action === 'reject' || !action}
                totalAmount={totalAmount}
                handleChange={handleChange}
            />
        )}
        {statesToShowActionsContainer.includes(status) && (
            <ChannelsCollateralDetailActions
                id={id}
                status={status}
                rate={rate}
                term={term}
                action={action}
                localInstrumentsUnderWarranty={localInstrumentsUnderWarranty}
                setAction={setAction}
                close={close}
                handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
            />
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

const defaultProps = {};

interface extraProps {
    row: CollateralDefinition;
    localInstrumentsUnderWarranty: collaterals[];
    totalAmount: number;
    action: string;
    setAction(action: string): void;
    close(): void;
    handleChange(instrument: string, name: string, keyToChange: string, value: string): void;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralDetail.propTypes = propTypes;
ChannelsCollateralDetail.defaultProps = defaultProps;

export default ChannelsCollateralDetail;
