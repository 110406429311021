import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import * as Yup from 'yup';
import SettingsBlocksEdit from './SettingsBlocksEdit';
import { useTranslation } from 'react-i18next';
import { Functionality, TPlatform, TUser } from 'types/settingsBlocks/settingsBlocks.api.types';
import { useUpdateBlocksFunctionality } from 'hooks/api/settingsBlocks.hooks';
import { AlertColor } from '@mui/material';
import { useFormik } from 'formik';
import { tRequiredFieldError } from 'constants/appConstants';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = ({ details, locked }: Functionality) => ({
    lockReason: details?.[0]?.lockReason || '',
    appliesTo: details?.[0]?.appliesTo || '',
    platform: details?.[0]?.platform || '',
    description: details?.[0]?.description || '',
    locked: locked || '',
});

const getValidationSchema = (t: Function) =>
    Yup.object().shape({
        lockReason: Yup.string()
            .test(
                'not-empty-or-placeholder',
                t(tRequiredFieldError),
                value => !!value && value.trim() !== '' && value.trim() !== '<p><br></p>',
            )
            .required(t(tRequiredFieldError)),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//
const SettingsBlocksEditContainer = (props: Props) => {
    const { row, setSnackBarMessage, close } = props;
    const { t } = useTranslation('settingsBlocks');
    const { mutate, isLoading } = useUpdateBlocksFunctionality(setSnackBarMessage, close);

    const platformOptions = ['WEB', 'MOBILE', 'ALL'];
    const appliesToOptions = ['CHANNEL', 'APP_USERS', 'ALL'];

    const readonlyNames = [
        'COLLATERAL',
        'EXCHANGE',
        'WITHDRAWAL_USDC',
        'WITHDRAWAL_USDMT',
        'WITHDRAWAL_USDCEXT',
        'WITHDRAWAL_USDCT',
    ];

    const hidePlatformAndAppliesTo = readonlyNames.includes(row.name);

    const handleSubmit = React.useCallback(async (values: any) => {
        const dataToEdit = {
            appliesTo: !hidePlatformAndAppliesTo ? values.appliesTo : ('CHANNEL' as TUser),
            platform: !hidePlatformAndAppliesTo ? values.platform : ('WEB' as TPlatform),
            description: '',
            lockReason: values.lockReason,
        };
        mutate({ id: row.id, body: { locked: values.locked, details: [dataToEdit] } });
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(row ?? {}),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );
    const formik = useFormik(formikInitProps);

    const handleChange = (name: string, value: any) => formik.setFieldValue(name, value);

    const childProps = {
        ...props,
        title: props.row.name,
        platformOptions,
        appliesToOptions,
        isLoading,
        handleChange,
        t,
        hidePlatformAndAppliesTo,
        formik,
    };

    return <SettingsBlocksEdit {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: Functionality;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsBlocksEditContainer.propTypes = propTypes;

export default SettingsBlocksEditContainer;
