import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CurrencyInput from './CurrencyInput';
import { CurrencyTypes } from 'types/common/general.types';
import { SxProps } from '@mui/material';
import { SourceInfo } from 'react-number-format';

const CurrencyInputContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CurrencyInput {...childProps} />;
};

const propTypes = {
    decimalScale: PropTypes.number,
    helperText: PropTypes.string,
};

interface extraProps {
    currency?: CurrencyTypes;
    disabled?: boolean;
    percentage?: boolean;
    error?: string | null;
    handleChange: (value: number | undefined, sourceInfo: SourceInfo) => void;
    value: string | number | null | undefined;
    sx?: SxProps;
    label?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CurrencyInputContainer.propTypes = propTypes;

export default CurrencyInputContainer;
