import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsModalHeader from './ChannelsModalHeader';
import { ModalHeaderProps } from 'types/common/channels.types';
import { useTranslation } from 'react-i18next';

const ChannelsModalHeaderContainer = (props: Props) => {
    const { t } = useTranslation('channelsCommon');

    const childProps = {
        ...props,
        t,
    };

    return <ChannelsModalHeader {...childProps} />;
};

const propTypes = {};

interface extraProps extends ModalHeaderProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalHeaderContainer.propTypes = propTypes;

export default ChannelsModalHeaderContainer;
