import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from 'api/utils/utils.api';
import instrumentsEndpoints from 'api/marketExteriorInstruments/marketExteriorInstruments.api';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useTranslation } from 'react-i18next';
import { download } from 'utils/helpers/commonHelper';
import { GetInstrumentsResponse } from 'types/common/instrument.types';
import React from 'react';
import { tErrorsContext } from 'constants/appConstants';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import { t } from 'i18next';
import { instrumentsAdapter } from 'adapters/common/instruments.adapter';

export const useCreateDocument = (config: any) => {
    const { data, mutate, isLoading, error, isError } = useMutation(endpoints.createDocument, {
        ...config,
    });
    return {
        data,
        mutate,
        isLoading,
        error: error ? t(errorResponseHelper(error as any), tErrorsContext) : null,
        isError,
    };
};
export const useGetFiles = (enabledCondition?: boolean, filePath?: string) => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['instruments', filePath],
        () => endpoints.downloadFile(filePath ?? ''),
        {
            enabled: enabledCondition,
            select: response => {
                const downloadPresignedUrl = response.data.downloadPresignedUrl;
                const res: string[] = [];
                Object.keys(downloadPresignedUrl).forEach(element => {
                    res.push(downloadPresignedUrl[element]);
                });
                return res[0]; // Analizar si devolver el primer elemento o un array completo
            },
        },
    );

    return {
        fileUrl: data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
export const useDownloadFile = () => {
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { t } = useTranslation('commonGrid');

    const { mutate, isLoading, error } = useMutation(endpoints.downloadFile, {
        onSuccess: res => {
            setSnackBarMessage(t('file_download_success'));
            const downloadPresignedUrl = res.data.downloadPresignedUrl;
            Object.keys(downloadPresignedUrl).forEach(element => {
                download(downloadPresignedUrl[element]);
            });
        },
        onError: () => {
            setSnackBarMessage(t('file_download_error'), 'error');
        },
    });

    return {
        downloadFile: mutate,
        isLoading,
        SnackBar,
        error: error ? errorResponseHelper(error) : null,
    };
};

export const useGetInstruments = (filterQueryString: string, enabled?: boolean) => {
    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['instruments', filterQueryString],
        () => instrumentsEndpoints.getInstruments(filterQueryString),
        {
            select: ({ data }: { data: GetInstrumentsResponse }) => instrumentsAdapter(data.items),
            enabled,
        },
    );

    const instrumentOptions = React.useMemo(() => {
        const loading = (isLoading && isFetching) || isRefetching;
        if (loading) return [];
        return data ?? [];
    }, [isLoading, isFetching, isRefetching, data]);

    return {
        data: instrumentOptions,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};
