import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import SettingsAlertsForm from './SettingsAlertsForm';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useEditAlert, useCreateAlert } from 'hooks/api/alerts.hooks';
import { ModalActionProps } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { Notification } from 'types/alerts/alerts.types';
import { UserException } from 'types/common/users.types';

// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//

const getInitialValues = ({
    id,
    name,
    active,
    blocking,
    recipients,
    platforms,
    message,
    exceptions,
}: Notification) => ({
    active: !!active,
    blocking: !!blocking,
    name: name || '',
    recipients: recipients || [],
    platforms: platforms || [],
    message: message || '',
    exceptions: exceptions || [],
});

const getValidationSchema = (t: Function) =>
    Yup.object().shape({
        name: Yup.string().required(t('alerts_name_required')),
        recipients: Yup.array().min(1, t('alerts_recipients_required')),
        message: Yup.string().required(t('alerts_message_required')),
    });

// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

const SettingsAlertsFormContainer = ({ row, isEdit, close, setSnackBarMessage }: Props) => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, isError, errorMessage, successfulMessage } = useCreateAlert();
    const {
        mutate: mutateEdit,
        isLoading: isLoadingEdit,
        isError: isErrorEdit,
        errorMessage: errorMessageEdit,
        successfulMessage: successfulMessageEdit,
    } = useEditAlert();

    const handleSubmit = React.useCallback(async (data: Notification) => {
        const body = {
            name: data.name,
            active: data.active,
            blocking: data.blocking,
            recipients: data.recipients,
            platforms: data.recipients.includes('channelUsers') ? ['web'] : data.platforms,
            message: data.message,
            exceptions: data?.exceptions?.map((e: UserException) => e.id) ?? [],
        };
        if (isEdit)
            return mutateEdit(
                { id: row.id, body },
                {
                    onSuccess: () => {
                        setSnackBarMessage(successfulMessageEdit);
                        close();
                    },
                },
            );
        mutate(body, {
            onSuccess: () => {
                setSnackBarMessage(successfulMessage);
                close();
            },
        });
    }, []);

    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(isEdit ? row : {}),
            validateOnChange: false,
            validateOnBlur: false,
            validateOnMount: false,
            validationSchema: getValidationSchema(t),
            onSubmit: handleSubmit,
        }),
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);
    const { values, handleChange } = formik;

    const filterValues = (name: string, option: string) => {
        const filteredValues = values?.platforms?.filter((key: string) => key !== option);
        handleChange({
            target: {
                name,
                value: filteredValues,
            },
        });
    };

    const handleChangePlatforms = (name: string, option: string, checked: boolean) =>
        checked
            ? handleChange({
                  target: {
                      name,
                      value: values?.platforms?.concat(option),
                  },
              })
            : filterValues(name, option);

    const childProps = {
        formik,
        isLoading,
        isLoadingEdit,
        t,
        handleChangePlatforms,
        close,
    };

    useEffect(() => {
        if (isError || isErrorEdit)
            setSnackBarMessage(isEdit ? errorMessageEdit : errorMessage, 'error');
    }, [isError, isErrorEdit]);

    return <SettingsAlertsForm {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps extends ModalActionProps {
    isEdit?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsAlertsFormContainer.propTypes = propTypes;
SettingsAlertsFormContainer.defaultProps = defaultProps;

export default SettingsAlertsFormContainer;
