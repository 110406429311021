import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelsAffidavitDetail.styled';
import { ChannnelsAffidavit } from 'types/channelsAffidavit/channelsAffidavit.types';
import { Divider, Grid } from '@mui/material';
import InfoLabel from 'components/common/InfoLabel';
import FileUploader from 'components/common/FileUploader';
import RejectForm from 'components/common/RejectForm';
import { getFullName } from 'utils/helpers/commonHelper';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import ActionButton from 'components/common/ActionButton';
import {
    ACCEPTED,
    CANCELLED,
    PENDING,
    REJECTED,
    tChannelsCommon,
    tCommon,
} from 'constants/appConstants';
import FileDownloader from 'components/common/FileDownloader';

const ChannelsAffidavitDetail = (props: Props) => {
    const {
        row,
        t,
        rejectReason,
        setRejectReason,
        errorMessage,
        fileUrl,
        setFileUrl,
        isLoading,
        close,
        handleSubmit,
        canApproveOrReject,
    } = props;
    const { declaration, ticker, createdAt, channel, customerCode, user, status } = row;

    return (
        <St.Container>
            <Grid container spacing={2} marginTop={'5px'}>
                <Grid item xs={12} md={5} ml={1.25}>
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_date_label') + ':'}
                        text={formatDateWithHour(createdAt)}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_channel_label') + ':'}
                        text={getFullName(channel)}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_customer_code_label') + ':'}
                        text={customerCode}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_operator_label') + ':'}
                        text={getFullName(declaration?.operator)}
                    />
                    {rejectReason && declaration?.status === REJECTED && (
                        <InfoLabel
                            fontSize={'14px'}
                            withoutSeparation
                            label={t('reason', tChannelsCommon) + ':'}
                            text={rejectReason}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={5} ml={1.25}>
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_client_label') + ':'}
                        text={getFullName(user)}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_instrument_label') + ':'}
                        text={ticker?.ticker}
                    />
                    <InfoLabel
                        fontSize={'14px'}
                        withoutSeparation
                        label={t('channels_affidavit_detail_currency_label') + ':'}
                        text={ticker.currency}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {(canApproveOrReject ||
                    (!declaration?.declarationPath && declaration?.status === ACCEPTED)) && (
                    <Divider sx={{ width: '100%', mt: 1.25 }} />
                )}
                {declaration?.filePath && (
                    <Grid item xs={12} ml={1.25}>
                        <FileDownloader fileUrl={declaration?.filePath} />
                    </Grid>
                )}
                {declaration?.declarationPath && (
                    <Grid item xs={12} ml={1.25}>
                        <FileDownloader fileUrl={declaration?.declarationPath} />
                    </Grid>
                )}
                {(declaration?.status === PENDING ||
                    (declaration?.status === REJECTED && status !== CANCELLED)) && (
                    <Grid item xs={12} ml={1.25}>
                        <FileUploader
                            label={t('ddjj_input_label')}
                            values={{ file: fileUrl }}
                            field="file"
                            setFieldValue={(field, value) => setFileUrl(value)}
                            errors={{ file: errorMessage ?? undefined }}
                        />
                    </Grid>
                )}
                {declaration?.status === ACCEPTED && !declaration.declarationPath && (
                    <Grid item xs={12} ml={1.25}>
                        <RejectForm
                            setReason={setRejectReason}
                            reason={rejectReason}
                            errorMessage={errorMessage}
                        />
                    </Grid>
                )}
            </Grid>
            {canApproveOrReject && (
                <St.FooterButtons direction={'row'} spacing={1}>
                    <ActionButton onClick={close} variant="outlined">
                        {t('cancel', tCommon)}
                    </ActionButton>
                    <ActionButton
                        loading={isLoading}
                        onClick={() =>
                            handleSubmit(
                                declaration?.status === PENDING || declaration?.status === REJECTED
                                    ? 'approve'
                                    : 'reject',
                            )
                        }
                        variant="contained">
                        {t('confirm', tCommon)}
                    </ActionButton>
                </St.FooterButtons>
            )}
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    rejectReason: PropTypes.string.isRequired,
    setRejectReason: PropTypes.func.isRequired,
    setFileUrl: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    canApproveOrReject: PropTypes.bool.isRequired,
};

interface extraProps {
    row: ChannnelsAffidavit;
    errorMessage: string | null;
    fileUrl: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsAffidavitDetail.propTypes = propTypes;

export default ChannelsAffidavitDetail;
