import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Grid, Typography } from '@mui/material';
import { tChannelsCommon } from 'constants/appConstants';
import ChannelsModalActions from 'components/common/ChannelsModalActions';
import { statesToShowActions } from 'utils/helpers/channelsCommonHelper';
import ChannelsModalActionButton from 'components/common/ChannelsModalActionButton';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';
import RejectForm from 'components/common/RejectForm';
import CurrencyInput from 'components/common/CurrencyInput';

const ChannelsOrdersActions = ({
    id,
    status,
    action,
    isLoading,
    rejectReason,
    declaration,
    t,
    handleAction,
    setRejectReason,
    handleSubmit,
    close,
    errorMessage,
    price,
    setPrice,
    handleAcquireOrReleaseOrder,
}: Props) => (
    <>
        {statesToShowActions.includes(status) && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <ChannelsModalActionButton status={status} setAction={type => handleAction(type)} />
                <Grid
                    container
                    item
                    direction="column"
                    alignSelf="baseline"
                    xs={6}
                    spacing={2}
                    ml={1.25}>
                    {(action === 'approve' || action === 'edit') && (
                        <Grid item xs>
                            <Typography fontSize={16} fontWeight={600} mb="15px">
                                {t(
                                    action === 'approve' ? 'for_approval' : 'to_edit',
                                    tChannelsCommon,
                                )}
                            </Typography>
                            <CurrencyInput
                                handleChange={value => setPrice(value)}
                                sx={{ width: '100%' }}
                                value={price}
                                decimalScale={2}
                                label={t('operation_price', tChannelsCommon)}
                                error={errorMessage ?? undefined}
                            />
                        </Grid>
                    )}

                    {action === 'reject' && (
                        <RejectForm
                            setReason={setRejectReason}
                            reason={rejectReason}
                            errorMessage={errorMessage}
                        />
                    )}
                </Grid>
            </>
        )}

        <Divider sx={{ width: '100%', mt: 2 }} />

        <ChannelsModalActions
            id={id}
            type="MARKET"
            status={status}
            loading={isLoading}
            disabled={isLoading}
            close={close}
            declaration={declaration}
            handleSubmit={handleSubmit}
            handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
        />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    setPrice: PropTypes.func.isRequired,
};

interface extraProps {
    id: string;
    status: string;
    action: string;
    isLoading: boolean;
    rejectReason?: string;
    close(): void;
    declaration?: AffidavitObject | null;
    handleAction(type: string): void;
    setRejectReason(rejectReason: string): void;
    handleSubmit: () => void;
    price: number;
    errorMessage: string | null;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsOrdersActions.propTypes = propTypes;

export default ChannelsOrdersActions;
