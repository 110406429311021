import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientBankAccountsPage from './ClientBankAccountsPage';
import { useTranslation } from 'react-i18next';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { ActionColumn } from 'types/common/CommonGrid/CommonGridFormModal.types';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { ClientBankAccountApi } from 'types/clientBankAccounts/clientBankAccounts.api.types';
import { getColorByStatus, getFullName } from 'utils/helpers/commonHelper';
import ClientBankAccountsDetails from './ClientBankAccountsDetails';
import { Chip } from '@mui/material';
import { tCommon } from 'constants/appConstants';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';

const ClientClientBankAccountsPageContainer = (props: Props) => {
    const { t } = useTranslation('clientBankAccounts');

    const columns: CommonGridColumnsProps<ClientBankAccountApi>[] = [
        {
            id: 'date',
            name: t('client_bank_accounts_list_date'),
            selector: row => formatDateWithHour(row.createdAt),
            sortField: 'createdAt',
            sortable: true,
            center: true,
        },
        {
            id: 'client',
            name: t('client_bank_accounts_list_client'),
            selector: row => getFullName(row.user),
            sortField: 'client',
            sortable: false,
            center: true,
        },
        {
            id: 'description',
            name: t('client_bank_accounts_list_description'),
            selector: row => row.description ?? '-',
            sortField: 'description',
            sortable: false,
            center: true,
        },
        {
            id: 'type',
            name: t('client_bank_accounts_list_account_type'),
            selector: row => row.type,
            cell: row => (
                <Chip
                    color="secondary"
                    variant="outlined"
                    sx={{ m: 0.625, minWidth: '100%' }}
                    label={t(row.type)}
                />
            ),
            sortField: 'type',
            sortable: true,
            center: true,
        },
        {
            id: 'currency',
            name: t('client_bank_accounts_list_account_currency'),
            selector: row => row.currency,
            sortField: 'currency',
            sortable: true,
            center: true,
        },
        {
            id: 'status',
            name: t('client_bank_accounts_list_account_state'),
            selector: row => t(row.status) as string,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '100%', ...getColorByStatus(row.status) }}
                    label={t(row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            customizedTitle: ({ status, type }) => (
                <ChannelsModalHeader
                    modalName="client_bank_accounts"
                    status={status}
                    operationType={type}
                />
            ),
            component: (props: any) => <ClientBankAccountsDetails {...props} />,
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        t,
    };

    return (
        <>
            <ClientBankAccountsPage {...childProps} />
        </>
    );
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientClientBankAccountsPageContainer.propTypes = propTypes;

export default ClientClientBankAccountsPageContainer;
