import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
// import St from './RuleTab.styled';
import { RulesTabValues } from 'types/settingsInstrumentRules/settingsInstrumentRules.types';
import { Grid } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import { FormikErrors } from 'formik';
import CustomAutoComplete from 'components/common/CustomAutoComplete';

const RuleTab = (props: Props) => {
    const { t, rulesTypesOptions, setFieldValue, values, errors } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <CustomInput
                    errors={errors}
                    field="description"
                    values={values}
                    placeholder={t('market_instrument_rules_form_description_placeholder')}
                    label={t('market_instrument_rules_form_description_label')}
                    setFieldValue={setFieldValue}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomAutoComplete
                    id="action"
                    value={values?.action ?? null}
                    onChange={(e, value) => {
                        setFieldValue('action', value);
                    }}
                    options={rulesTypesOptions ?? []}
                    label={t('market_instrument_rules_form_action_label')}
                    placeholder={t('market_instrument_rules_form_action_placeholder')}
                    renderOption={(props, option) => (
                        <li {...props}>
                            <div>{option.name}</div>
                        </li>
                    )}
                />
            </Grid>
            {values?.action?.value !== 'SOFT DELETE' && (
                <Grid item xs={12} md={6}>
                    <CustomInput
                        errors={errors}
                        field="reason"
                        values={values}
                        placeholder={t('market_instrument_rules_form_reason_placeholder')}
                        label={t('market_instrument_rules_form_reason_label')}
                        setFieldValue={setFieldValue}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

interface extraProps {
    values: RulesTabValues;
    rulesTypesOptions: { id: number; value: string; name: string }[];
    errors: FormikErrors<RulesTabValues>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RuleTab.propTypes = propTypes;

export default RuleTab;
