import St from './ChannelsOrdersDetail.styled';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { AlertColor, Divider, Grid } from '@mui/material';
import { ERROR, tChannelsCommon, tCommon } from 'constants/appConstants';
import ChannelsOrdersActions from '../ChannelsOrdersActions';
import { Order } from 'types/channelsOrders/channelsOrders.types';
import ChannelsModalItemGrid from 'components/common/ChannelsModalItemGrid';
import ChannelsDDJJCellInfo from 'components/common/ChannelsDDJJCellInfo';
import { convertCurrencyFormat, getFullName } from 'utils/helpers/commonHelper';
import { formatDateWithHour } from 'utils/helpers/dateHelper';

const ChannelsOrdersDetail = ({
    row,
    close,
    t,
    setSnackBarMessage,
    getPriceByMarketStatus,
    handleAcquireOrReleaseOrder,
}: Props) => {
    const {
        channel,
        amount,
        operator,
        term,
        price,
        isMarketPrice,
        customerCode,
        declaration,
        lastModified,
        ticker,
        user,
        reason,
        quantity,
        createdAt,
        updatedAt,
        operatedAmount,
        operatedPrice,
        status,
        instrument,
        errorMessage,
    } = row;

    return (
        <Grid container spacing={2} px={3} py={2}>
            <Grid item xs={12} display="flex" gap="20px">
                <St.DetailTitle>{t('information', tChannelsCommon)}</St.DetailTitle>
                <St.DetailTitle>{t('additional_information', tChannelsCommon)}</St.DetailTitle>
            </Grid>
            <Grid item container xs={12} md={6}>
                <ChannelsModalItemGrid label={t('customer_number')} text={customerCode} />
                <ChannelsModalItemGrid label={t('instrument')} text={ticker.ticker} />
                <ChannelsModalItemGrid
                    label={t('term')}
                    text={t(`term_${term}`, { ns: 'channelsOrders' })}
                />
                <ChannelsModalItemGrid label={t('currency')} text={ticker.currency} />
                <ChannelsModalItemGrid
                    label={t('amount')}
                    text={convertCurrencyFormat(amount, ticker.currency)}
                />
                <ChannelsModalItemGrid
                    label={t('price')}
                    text={getPriceByMarketStatus(isMarketPrice, price, ticker.currency)}
                />
                <ChannelsModalItemGrid
                    label={t('operated_amount')}
                    text={convertCurrencyFormat(
                        instrument.type === 'BOND' ? operatedAmount / 100 : operatedAmount,
                        ticker.currency,
                    )}
                />
                <ChannelsModalItemGrid
                    label={t('operated_price')}
                    text={convertCurrencyFormat(operatedPrice, ticker.currency)}
                />
                <ChannelsModalItemGrid label={t('nominal')} text={quantity} />
            </Grid>
            <Grid item container xs={12} md={6}>
                <ChannelsModalItemGrid label={t('date')} text={formatDateWithHour(createdAt)} />
                <ChannelsModalItemGrid label={t('channel')} text={getFullName(channel)} />
                <ChannelsModalItemGrid label={t('customer')} text={getFullName(user)} />
                <ChannelsModalItemGrid
                    label={t('qualified_investor')}
                    text={t(user.isQualifiedInvestor ? 'yes' : 'no', tCommon)}
                />
                <ChannelsModalItemGrid
                    label={t('ddjj_required')}
                    text={t(declaration ? 'yes' : 'no', tCommon)}
                />

                <ChannelsModalItemGrid label={t('operator')} text={getFullName(operator)} />
                {status === ERROR && errorMessage && (
                    <ChannelsModalItemGrid label={t('error_message')} text={errorMessage} />
                )}
            </Grid>
            {lastModified && (
                <ChannelsModalItemGrid
                    column={12}
                    label={t('modified')}
                    text={`${getFullName(lastModified)} - ${formatDateWithHour(updatedAt)}`}
                />
            )}
            {reason && (
                <>
                    <Divider sx={{ width: '100%', mt: 1.25 }} />
                    <ChannelsModalItemGrid column={12} label={t('reason')} text={reason} />
                </>
            )}

            <ChannelsOrdersActions
                row={row}
                close={close}
                setSnackBarMessage={setSnackBarMessage}
                handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
            />
        </Grid>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: Order;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    getPriceByMarketStatus: (isMarketPrice: boolean, price: number, currency?: string) => string;
    handleAcquireOrReleaseOrder({ id, action }: { id: string; action: string }): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsOrdersDetail.propTypes = propTypes;

export default ChannelsOrdersDetail;
