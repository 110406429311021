import { createTheme, responsiveFontSizes } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CustomThemeOptions } from './types/theme.types';
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        custom: {
            [key: string]: any;
        };
    }
}

export const defaultTheme = createTheme({
    palette: {
        background: {
            default: '#FDFDFD',
        },
        error: {
            main: red.A400,
        },
        // mode: 'dark',
        primary: {
            light: '#484848',
            main: '#307FE2',
            // main: '#2a86e4',
            contrastText: '#fff',
        },
        secondary: {
            light: '#e5ceff',
            main: '#173254',
            dark: '#476293',
            contrastText: '#fff',
        },
        custom: {
            sidebar: '#F2F2F2',
            whiteSmoke: '#f0f0f0',
            darkblue: '#173254',
            darkblue2: '#2e4665',
            darkblue3: '#455b76',
            darkblue4: '#5d7087',
            darkblue5: '#748498',
            darkblue6: '#8B99A9',
            darkblue7: '#A2ADBB',
            darkblue8: '#B9C1CC',
            darkblue9: '#D1D6DD',
            darkblue10: '#E8EAEE',
            vibrantblue: '#307FDD',
            vibrantblue2: '#307FDD',
            vibrantblue3: '#5999E4',
            vibrantblue4: '#6EA5E7',
            vibrantblue5: '#83B2EB',
            vibrantblue6: '#98BFEE',
            vibrantblue7: '#ACCCF1',
            vibrantblue8: '#C1D9F5',
            vibrantblue9: '#D6E5F8',
            vibrantblue10: '#EAF2FC',
            vibrantblue11: '#D6E5F8',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '"Montserrat"',
            '-apple-system, system-ui',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
} as CustomThemeOptions);

export default responsiveFontSizes(defaultTheme);
