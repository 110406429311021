import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from '../../api/alerts/alerts.api';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { alertsAdapter } from 'adapters/api/alerts.adapter';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';

export const useAlerts = ({ forceDisable, filterQueryString }: GridHookParams) => {
    const enabledCondition = !forceDisable;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['alerts', filterQueryString],
        () => endpoints.getAlerts(filterQueryString),
        {
            enabled: enabledCondition,
            select: data => {
                return alertsAdapter(data?.data);
            },
        },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
    };
};

export const useCreateAlert = () => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.createAlert);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t((error as any)?.response?.data?.errorCode ?? '', tErrorsContext),
        successfulMessage: t('alerts_created_successfully'),
    };
};

export const useEditAlert = () => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.editAlert);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
        successfulMessage: t('alerts_edited_successfully'),
    };
};

export const useDeleteAlert = () => {
    const { t } = useTranslation('alerts');
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteAlert);

    return {
        mutate,
        isLoading,
        isError,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
        successfulMessage: t('alerts_deleted_successfully'),
    };
};
