import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import ChannelsDDJJCellInfo from './ChannelsDDJJCellInfo';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';
import { useDownloadFile } from 'hooks/api/utils.hooks';

const ChannelsDDJJCellInfoContainer = (props: Props) => {
    const { t } = useTranslation('common');
    const { downloadFile, isLoading, SnackBar } = useDownloadFile();

    const childProps = {
        ...props,
        downloadFile,
        isLoading,
        SnackBar,
        t,
    };

    return <ChannelsDDJJCellInfo {...childProps} />;
};

const propTypes = {};

interface extraProps {
    declaration?: AffidavitObject | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsDDJJCellInfoContainer.propTypes = propTypes;

export default ChannelsDDJJCellInfoContainer;
