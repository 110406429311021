import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { DATES_BASE_URL } from 'constants/appConstants';
import { GetHolidayResponse } from 'types/common/dates.types';

const apiName = 'holidays';

export default {
    getHoliday: () =>
        withFakeData<GetHolidayResponse>(
            () =>
                axiosDataInstance.get(
                    `${DATES_BASE_URL}/bo/holidays?page=1&pageSize=200&sort=ASC&marketIds=11`,
                ),
            apiName,
            'holiday',
            false,
            500,
        ),
};
