import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './FileDownloader.styled';
import { SvgIcon } from '@mui/material';
import { tCommon } from 'constants/appConstants';

const FileDownloader = (props: Props) => {
    const {
        t,
        error,
        fileUrl,
        isLoading,
        downloadFile,
        fileExtension,
        fileIconMapper,
        label,
        filename,
    } = props;

    return (
        <St.FileUploaderWrapper>
            <St.BoxWrapper>
                {label && <St.Typography>{label}</St.Typography>}
                {fileUrl && !isLoading && (
                    <>
                        <SvgIcon
                            component={fileIconMapper[fileExtension] || fileIconMapper.default}
                            inheritViewBox
                        />
                        <St.FileName>{filename}</St.FileName>
                    </>
                )}
                {error && <St.ErrorText text={error} />}
            </St.BoxWrapper>
            <St.LoadingButton
                loading={isLoading}
                disabled={isLoading}
                onClick={() => (fileUrl ? downloadFile(fileUrl) : null)}
                className={'isDelete'}>
                <St.Label>{t('download', tCommon)}</St.Label>
            </St.LoadingButton>
        </St.FileUploaderWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    fileUrl: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    fileExtension: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
};

interface extraProps {
    error: string | null;
    fileIconMapper: { [key: string]: React.FunctionComponent };
    label?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FileDownloader.propTypes = propTypes;

export default FileDownloader;
