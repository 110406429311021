import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Button, Divider, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import CustomCheckbox from 'components/common/CustomCheckbox';
import { tCommon } from 'constants/appConstants';
import CustomTextEditorContainer from 'components/common/CustomTextEditor';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider } from 'formik';

const SettingsBlocksEdit = ({
    title,
    platformOptions,
    appliesToOptions,
    isLoading,
    close,
    handleChange,
    t,
    hidePlatformAndAppliesTo,
    formik,
}: Props) => {
    const { errors, values } = formik;

    return (
        <>
            <FormikProvider value={formik} data-testid="SettingsAlertsForm-form">
                <Form>
                    <Grid container spacing={2} px={3} py={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography>{t(title)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CustomCheckbox
                                name="locked"
                                label={t('settings_blocks_locked_column') as string}
                                checked={!!values.locked}
                                handleChange={({ target: { name, checked } }) =>
                                    handleChange(name, checked)
                                }
                            />
                        </Grid>

                        {!hidePlatformAndAppliesTo && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <Typography mb={0.625}>
                                        {t('settings_blocks_platform')}
                                    </Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.platform || ''}
                                            onChange={({ target: { value } }) =>
                                                handleChange('platform', value)
                                            }>
                                            {platformOptions.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {t(option)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography mb={0.625}>
                                        {t('settings_blocks_appliesTo')}
                                    </Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            value={values.appliesTo || ''}
                                            onChange={({ target: { value } }) =>
                                                handleChange('appliesTo', value)
                                            }>
                                            {appliesToOptions.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {t(option)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <Divider sx={{ width: '100%', my: 1.25 }} />
                            <Typography fontSize={20} mb={0.625}>
                                {t('settings_blocks_reason')}
                            </Typography>
                            <Typography fontSize={12} mb={0.625}>
                                {t('settings_blocks_reason_message')}
                            </Typography>
                            <CustomTextEditorContainer
                                value={values?.lockReason}
                                messageError={errors.lockReason ? errors.lockReason : ''}
                                placeholder={t('settings_blocks_reason') as string}
                                onBlur={(val: string) => {
                                    formik.setFieldValue('lockReason', val);
                                }}
                                height={200}
                                limitChars={150}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end">
                            <Grid item xs={3}>
                                <Button fullWidth size="small" onClick={close} variant="outlined">
                                    {t('cancel', tCommon)}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <LoadingButton
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                    loading={isLoading}>
                                    {t('save', tCommon)}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    formik: any;
    title: string;
    platformOptions: string[];
    appliesToOptions: string[];
    isLoading: boolean;
    close(): void;
    handleChange(name: string, value: any): void;
    hidePlatformAndAppliesTo: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
SettingsBlocksEdit.propTypes = propTypes;

export default SettingsBlocksEdit;
