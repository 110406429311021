import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CurrencyTypes } from 'types/common/general.types';
import { NumericFormat, SourceInfo } from 'react-number-format';
import { SxProps, TextField } from '@mui/material';

const CurrencyInput = (props: Props) => {
    const {
        decimalScale = 2,
        currency = 'ARS',
        disabled = false,
        percentage = false,
        error,
        helperText,
        handleChange,
        value,
        sx,
        label = '',
    } = props;

    return (
        <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={decimalScale ?? 2}
            fixedDecimalScale={true}
            allowNegative={false}
            prefix={percentage ? '' : currency == 'ARS' ? '$' : 'US$'}
            value={value}
            customInput={TextField}
            placeholder={'0.00'}
            suffix={percentage ? '%' : ''}
            size="small"
            disabled={disabled}
            onValueChange={(e, sourceInfo) => handleChange(e.floatValue, sourceInfo)}
            error={!!error}
            helperText={error !== null ? error : helperText || ''}
            sx={sx}
            label={label}
        />
    );
};

const propTypes = {
    decimalScale: PropTypes.number,
    helperText: PropTypes.string,
};

interface extraProps {
    currency?: CurrencyTypes;
    disabled?: boolean;
    error?: string | null;
    handleChange: (value: number | undefined, sourceInfo: SourceInfo) => void;
    value: string | number | null | undefined;
    sx?: SxProps;
    label?: string;
    percentage?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CurrencyInput.propTypes = propTypes;

export default CurrencyInput;
