import { useState, useEffect } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ChannelsCollateralPage from './ChannelsCollateralPage';
import { useTranslation } from 'react-i18next';
import {
    ActionColumn,
    HeaderFiltersProps,
} from 'types/common/CommonGrid/CommonGridFormModal.types';
import { CommonGridColumnsProps } from 'types/common/CommonGrid/CommonGridColumnProps.types';
import { CollateralDefinition } from 'types/channelsCollateral/channelsCollateral.types';
import {
    ASSIGNED,
    AUTH_BASE_URL,
    INSTRUMENTS_BASE_URL,
    LOCK,
    PENDING,
    UNLOCK,
    tChannelsCommon,
    tCommon,
    tDate,
} from 'constants/appConstants';
import CustomIconSelector from 'components/common/CustomIconSelector';
import { useLockOrUnlockCollateral } from 'hooks/api/channelsCollateral.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { Chip, Typography } from '@mui/material';
import ChannelsCollateralDetail from './ChannelsCollateralDetail';
import { statusOptions } from 'utils/helpers/channelsCommonHelper';
import ChannelsModalHeader from 'components/common/ChannelsModalHeader';
import { getFullName, getColorByStatus, convertCurrencyFormat } from 'utils/helpers/commonHelper';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { UPDATE_ORDERS_COLLATERAL } from 'constants/abilities.constants';

const ChannelsCollateralPageContainer = (props: Props) => {
    const { t } = useTranslation('channelsCollateral');
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const { mutate, isError, errorMessage } = useLockOrUnlockCollateral();
    const { isHiddenByAbility } = useGetActionsReestrictions();
    const [refreshTable, setRefreshTable] = useState(false);

    const handleAcquireOrReleaseOrder = (infoToLockOrUnlock: { id: string; action: string }) =>
        mutate(infoToLockOrUnlock, {
            onSuccess: () => {
                setSnackBarMessage(t(`collateral_${infoToLockOrUnlock.action}_successfully`));
                setRefreshTable(prevValue => !prevValue);
            },
        });

    const columns: CommonGridColumnsProps<CollateralDefinition>[] = [
        {
            id: 'customerCode',
            name: t('collateral_list_customer_number_column'),
            selector: row => row.customerCode,
            sortField: 'customerCode',
            sortable: true,
            center: true,
        },
        {
            id: 'user',
            name: t('collateral_list_client_column'),
            selector: ({ user }) => getFullName(user),
            sortField: 'user',
            sortable: false,
            center: true,
        },
        {
            id: 'buySell',
            name: t('collateral_list_operation_type_column'),
            selector: row => row.buySell,
            cell: row => (
                <Chip
                    variant="outlined"
                    color="secondary"
                    sx={{ m: 0.625, px: 1 }}
                    label={t(`collateral_${row.buySell}`, tChannelsCommon)}
                />
            ),
            sortField: 'buySell',
            sortable: true,
            center: true,
        },
        {
            id: 'amount',
            name: t('collateral_list_amount_column'),
            selector: row => convertCurrencyFormat(row.amount),
            sortField: 'amount',
            sortable: true,
            center: true,
        },
        {
            id: 'currency',
            name: t('collateral_list_currency_column'),
            selector: row => row.currency,
            cell: row => (
                <Typography fontSize={14} fontWeight={700} textAlign="center">
                    {row.currency}
                </Typography>
            ),
            sortField: 'currency',
            sortable: true,
            center: true,
        },
        {
            id: 'status',
            name: t('collateral_list_state_column'),
            selector: row => row.status,
            cell: row => (
                <Chip
                    sx={{ m: 0.625, minWidth: '100%', ...getColorByStatus(row.status) }}
                    label={t(row.status, tCommon)}
                />
            ),
            sortField: 'status',
            sortable: true,
            center: true,
        },
        {
            id: 'operator',
            name: t('collateral_list_operator_column'),
            selector: ({ operator }) => operator?.name || '-',
            sortField: 'operator',
            sortable: false,
            center: true,
        },
    ];

    const actionColumns: ActionColumn[] = [
        {
            id: 'detail',
            icon: (props: any) => <CustomIconSelector type="detail" sx={props.sx} />,
            width: 'xl',
            customizedTitle: ({ status, buySell }: any) => (
                <ChannelsModalHeader
                    modalName="collateral"
                    status={status}
                    operationType={`collateral_${buySell}`}
                />
            ),
            component: (props: any) => (
                <ChannelsCollateralDetail
                    handleAcquireOrReleaseOrder={handleAcquireOrReleaseOrder}
                    {...props}
                />
            ),
        },
        {
            id: 'assing',
            icon: (props: any) => <CustomIconSelector type="assing" sx={props.sx} />,
            isHidden: (row: any) =>
                row?.status !== PENDING && !isHiddenByAbility(UPDATE_ORDERS_COLLATERAL),
            onIconClick: ({ id }: any) => handleAcquireOrReleaseOrder({ id, action: LOCK }),
        },
        {
            id: 'unassing',
            icon: (props: any) => <CustomIconSelector type="unassing" sx={props.sx} />,
            isHidden: (row: any) =>
                row?.status !== ASSIGNED && !isHiddenByAbility(UPDATE_ORDERS_COLLATERAL),
            onIconClick: ({ id }: any) => handleAcquireOrReleaseOrder({ id, action: UNLOCK }),
        },
    ];

    const headerFilters: HeaderFiltersProps[] = [
        {
            id: 'user',
            label: t('customer', tChannelsCommon),
            type: 'text',
            placeholder: t('customer', tChannelsCommon),
            query: 'user',
        },
        {
            id: 'customerCode',
            label: t('customer_number', tChannelsCommon),
            type: 'text',
            placeholder: t('customer_number', tChannelsCommon),
            query: 'customerCode',
        },
        {
            id: 'channel',
            label: t('channel', tChannelsCommon),
            type: 'requestAutocomplete',
            placeholder: t('channel_placeholder', tChannelsCommon),
            query: 'channel',
            requestAutocomplete: {
                requestUrl: `${AUTH_BASE_URL}/app_users/search`,
                param: 'name',
                optionLabel: 'name',
                keyTofilter: 'name',
                extraFilter: '&userType=CHANNEL',
            },
        },
        {
            id: 'instrument',
            label: t('instrument', tChannelsCommon),
            type: 'requestAutocomplete',
            placeholder: t('instrument_placeholder', tChannelsCommon),
            query: 'ticker',
            requestAutocomplete: {
                requestUrl: `${INSTRUMENTS_BASE_URL}/bo/`,
                param: 'ticker',
                optionLabel: 'name',
                keyTofilter: 'tickers[0].ticker',
            },
        },
        {
            id: 'operator',
            label: t('operator', tChannelsCommon),
            type: 'requestAutocomplete',
            placeholder: t('operator_placeholder', tChannelsCommon),
            query: 'operator',
            requestAutocomplete: {
                requestUrl: `${AUTH_BASE_URL}/bo_users/search`,
                param: 'name',
                optionLabel: 'name',
                keyTofilter: 'name',
            },
        },
        {
            id: 'status',
            label: t('status', tChannelsCommon),
            type: 'select',
            placeholder: '',
            query: 'status',
            options: statusOptions,
        },
        {
            id: 'dateFrom',
            label: t('from', tDate),
            type: 'date',
            placeholder: t('select_date', tDate),
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tDate),
            type: 'date',
            placeholder: t('select_date', tDate),
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        columns,
        actionColumns,
        headerFilters,
        refreshTable,
        t,
        SnackBar,
    };

    useEffect(() => {
        if (isError) setSnackBarMessage(errorMessage, 'error');
    }, [isError]);

    return <ChannelsCollateralPage {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsCollateralPageContainer.propTypes = propTypes;
ChannelsCollateralPageContainer.defaultProps = defaultProps;

export default ChannelsCollateralPageContainer;
