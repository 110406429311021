import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box } from '@mui/material';
import { ICON_CELL_SIZE, tModal } from 'constants/appConstants';
import CustomIconSelector from '../CustomIconSelector/CustomIconSelector';
import { AffidavitObject } from 'types/channelsAffidavit/channelsAffidavit.types';
import ActionIconButton from '../ActionIconButton';

const ChannelsDDJJCellInfo = ({
    declaration,
    downloadFile,
    isLoading,
    SnackBar,
    downloadUrl,
    t,
}: Props) => (
    <Box component="span" display="flex" alignItems="center">
        {t(declaration?.filePath ? 'yes' : 'no')} &nbsp;
        <CustomIconSelector
            type={declaration?.filePath ? 'check' : 'close'}
            sx={{
                color: declaration?.filePath ? 'success.main' : 'secondary.main',
                ...ICON_CELL_SIZE,
            }}
        />
        {downloadUrl && (
            <>
                <ActionIconButton
                    tooltipText={t('download', tModal)}
                    disabled={isLoading}
                    icon={<CustomIconSelector type="download" />}
                    handleClick={() => downloadFile(downloadUrl)}
                />
                <SnackBar />
            </>
        )}
    </Box>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    downloadUrl?: string;
    declaration?: AffidavitObject | null;
    downloadFile(fileUrl: string): void;
    isLoading: boolean;
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsDDJJCellInfo.propTypes = propTypes;

export default ChannelsDDJJCellInfo;
