import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BankAccountDetail from './BankAccountDetail';
import { useTranslation } from 'react-i18next';
import { BankAccountExtraData } from 'types/clientExtractions/clientExtractions.types';
import { useState } from 'react';
import { useDownloadFile } from 'hooks/api/utils.hooks';

const BankAccountDetailContainer = (props: Props) => {
    const { t } = useTranslation();
    const [showEditModal, setShowEditModal] = useState(false);
    const { downloadFile, isLoading, SnackBar } = useDownloadFile();

    const childProps = {
        ...props,
        showEditModal,
        setShowEditModal,
        downloadFile,
        isLoading,
        SnackBar,
        t,
    };

    return <BankAccountDetail {...childProps} />;
};

const propTypes = {};

const defaultProps = {};

interface extraProps {
    type: string;
    accountDetail: BankAccountExtraData;
    bankAccountId?: string;
    editable?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountDetailContainer.propTypes = propTypes;
BankAccountDetailContainer.defaultProps = defaultProps;

export default BankAccountDetailContainer;
