import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { SvgIcon } from '@mui/material';
import { Tprop } from 'types/common/general.types';
import PdfReaderModal from '../PdfReaderModal';
import ImageReaderModal from '../ImageReaderModal';
import St from './FileUploader.styled';

const FileUploader = (props: Props) => {
    const {
        t,
        isLoading,
        openDocumentReader,
        handleCloseDocumentReader,
        handleOpenDocumentReader,
        displayCleanerFunction = true,
        cleanerFunction,
        handleFileSelectorChange,
        accept,
        error,
        fileExtension,
        fileIconMapper,
        file,
        fileUrl,
        canOpenPdfReader,
        canOpenImgReader,
        filename,
        label,
    } = props;

    return (
        <St.FileUploaderWrapper>
            <St.BoxWrapper>
                {label && <St.Typography>{label}</St.Typography>}
                {(file || fileUrl) && !isLoading && (
                    <St.Tooltip
                        title={t('file_uploader_preview_item_tooltip_msn')}
                        onClick={handleOpenDocumentReader}>
                        <St.TooltipContentWrapper>
                            <St.IconButtonSvg onClick={handleOpenDocumentReader}>
                                <SvgIcon
                                    component={
                                        fileIconMapper[fileExtension] || fileIconMapper.default
                                    }
                                    inheritViewBox
                                />
                            </St.IconButtonSvg>
                            <St.FileName>{filename}</St.FileName>
                        </St.TooltipContentWrapper>
                    </St.Tooltip>
                )}
                {error && <St.ErrorText text={error} />}
            </St.BoxWrapper>
            <St.LoadingButton
                loading={isLoading}
                disabled={isLoading || !displayCleanerFunction}
                onClick={() => (file || fileUrl ? cleanerFunction() : null)}
                className={file || fileUrl ? 'isDelete' : ''}>
                {file || fileUrl ? (
                    <St.Label>{t('delete_text')}</St.Label>
                ) : (
                    <>
                        <St.Label htmlFor="fileInput">{t('attach')}</St.Label>
                        <input
                            id="fileInput"
                            type="file"
                            hidden
                            onChange={(e: any) => handleFileSelectorChange(e)}
                            accept={accept}
                        />
                    </>
                )}
            </St.LoadingButton>
            {canOpenImgReader && (file || fileUrl) && (
                <ImageReaderModal
                    open={openDocumentReader}
                    handleClose={handleCloseDocumentReader}
                    imgUrl={fileUrl || (file && URL.createObjectURL(file))}
                />
            )}
            {canOpenPdfReader && (file || fileUrl) && (
                <PdfReaderModal
                    open={openDocumentReader}
                    handleClose={handleCloseDocumentReader}
                    pdfUrl={fileUrl || (file && URL.createObjectURL(file))}
                />
            )}
        </St.FileUploaderWrapper>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    openDocumentReader: PropTypes.bool.isRequired,
    canOpenPdfReader: PropTypes.bool.isRequired,
    canOpenImgReader: PropTypes.bool.isRequired,
    fileExtension: PropTypes.string.isRequired,
    validExtensions: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
};

interface extraProps extends Tprop {
    displayCleanerFunction?: boolean;
    error: string | null;
    handleCloseDocumentReader: () => void;
    handleOpenDocumentReader: () => void;
    cleanerFunction: () => void;
    handleFileSelectorChange: (e: any) => Promise<void>;
    accept?: string;
    fileIconMapper: { [key: string]: React.FunctionComponent };
    file: File | null;
    fileUrl?: string;
    label?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FileUploader.propTypes = propTypes;

export default FileUploader;
