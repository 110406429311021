import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid } from '@mui/material';
import { ASSIGNED, LOCK, PENDING, UNLOCK, tCommon } from 'constants/appConstants';
import ActionButton from '../ActionButton';
import { ChannelsModalActionsProps } from 'types/common/channels.types';
import { statesToShowActions } from 'utils/helpers/channelsCommonHelper';
import ActionIconButton from '../ActionIconButton';
import CustomIconSelector from '../CustomIconSelector';

const ChannelsModalActions = ({
    id,
    status,
    loading,
    disabled,
    declaration,
    t,
    close,
    handleSubmit,
    handleAcquireOrReleaseOrder,
    SnackBar,
    downloadFile,
    canAcquireOrRelease,
}: Props) => (
    <>
        <Grid container item xs={6} alignItems="center" pt={1}>
            {status === ASSIGNED && canAcquireOrRelease && (
                <Grid item xs={3}>
                    <ActionIconButton
                        tooltipText={t(UNLOCK)}
                        icon={<CustomIconSelector type="unassing" />}
                        handleClick={() => handleAcquireOrReleaseOrder({ id, action: UNLOCK })}
                    />
                </Grid>
            )}
            {status === PENDING && canAcquireOrRelease && (
                <Grid item xs={3}>
                    <ActionIconButton
                        tooltipText={t(LOCK)}
                        handleClick={() => handleAcquireOrReleaseOrder({ id, action: LOCK })}
                        icon={<CustomIconSelector type="assing" />}
                    />
                </Grid>
            )}
            {declaration?.filePath && (
                <Grid item xs={3}>
                    <ActionIconButton
                        tooltipText={t('download_ddjj')}
                        icon={<CustomIconSelector type="download" />}
                        handleClick={() => downloadFile(declaration.filePath)}
                    />
                </Grid>
            )}
        </Grid>
        {statesToShowActions.includes(status) && (
            <Grid container item xs={6} alignItems="center" pt={1}>
                <Grid item xs={6} display="flex" justifyContent="end">
                    <ActionButton
                        variant="outlined"
                        onClick={close}
                        sx={{ mt: 0 }}
                        disabled={disabled || loading}>
                        {t('back', tCommon)}
                    </ActionButton>
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="end">
                    <ActionButton
                        disabled={disabled}
                        loading={loading}
                        sx={{ mt: 0 }}
                        onClick={handleSubmit}>
                        {t('save', tCommon)}
                    </ActionButton>
                </Grid>
            </Grid>
        )}
        <SnackBar />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    canAcquireOrRelease: PropTypes.bool.isRequired,
};

const defaultProps = {};

interface extraProps extends ChannelsModalActionsProps {
    SnackBar: () => JSX.Element;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelsModalActions.propTypes = propTypes;
ChannelsModalActions.defaultProps = defaultProps;

export default ChannelsModalActions;
