import { useQuery } from '@tanstack/react-query';
import { holidayAdapterForCollateral } from 'adapters/api/dates.adapter';
import endpoints from 'api/dates/dates.api';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';

export const useGetHolidayForCollaterals = () => {
    const { data, isLoading, error, isFetching, isRefetching, isError, refetch } = useQuery(
        ['collateral-holiday'],
        () => endpoints.getHoliday(),
        { select: data => holidayAdapterForCollateral(data.data) },
    );

    return {
        data,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
        isError,
    };
};
