import { useEffect, useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientBankAccountsDetails from './ClientBankAccountsDetails';
import { useTranslation } from 'react-i18next';
import { ClientBankAccountApi } from 'types/clientBankAccounts/clientBankAccounts.api.types';
import { useGetActionsReestrictions } from 'hooks/common/roles.hooks';
import { SortedBankAccount } from 'types/clientExtractions/clientExtractions.types';
import { LOCAL_ACCOUNTS } from 'constants/appConstants';
import { sortBankAccounts } from 'utils/helpers/commonHelper';
import { AlertColor } from '@mui/material';

const ClientBankAccountsDetailsContainer = (props: Props) => {
    const { t } = useTranslation('clientBankAccounts');
    const { isHiddenByAbility } = useGetActionsReestrictions();
    const [accountsInfo, setAccountInfo] = useState<SortedBankAccount | undefined>(
        {} as SortedBankAccount,
    );

    const childProps = {
        ...props,
        accountsInfo,
        t,
        isHiddenByAbility,
    };

    useEffect(() => {
        setAccountInfo(undefined);
        if (!LOCAL_ACCOUNTS.includes(props.row.type)) {
            // Si la cuenta es del exterior, ordenar las cuentas destino, broker o intermediarios
            const accounts = sortBankAccounts(props.row.bankAccountExtraData);
            setAccountInfo(accounts);
        }
    }, [props.row]);

    return <ClientBankAccountsDetails {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: ClientBankAccountApi;
    close(): void;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientBankAccountsDetailsContainer.propTypes = propTypes;

export default ClientBankAccountsDetailsContainer;
